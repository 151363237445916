/* eslint-disable @stylistic/comma-dangle */

import { sharedLocale }	from "@geotoura/shared/sharedLocale_de";
import { numberFormat }	from "@geotoura/shared/numberFormat_en";
import * as fbModel		from "@geotoura/shared/fbModel";

import { Messages }		from "@geotoura/fb/locale";

export const messages:Messages = {
	format:	{
		number:	numberFormat,
	},
	nav: {
		start:		"Start planning your trip",
		back:		"Back",
		continue:	"Continue",
		skip:		"Skip",
		send:		"Send",
		finish:		"Back to the homepage",
	},
	closeButton: {
		sr:	"Close"
	},
	text: {
		errorHeading:	"Error",
		errorText1:		"Unfortunately, an error occurred while saving your data.",
		errorText2:		"Please try again at a later date or contact us at ",
		mailLink:		sharedLocale.email.info,
		errorText3:		" or ",
		phoneLink:		sharedLocale.phone,
	},
	modal: {
		close:	"Close",
		submit:	"OK"
	},
	selectlist: {
		selectRegion:	"Select region"
	},
	sliderLabel: (minLabel:string, maxLabel:string):string =>
		`Choose a value on a scale from ${fbModel.SliderValues.minimum} (${minLabel}) to ${fbModel.SliderValues.maximum}(${maxLabel})`,
	budget: {
		screenreaderHint: "You can choose one of four amounts using the following four buttons or adjust them flexibly using a slider.",
		sliderLabel: (minLabel:string, maxLabel:string):string =>
			`Choose your total travel budget on a scale 0 for ${minLabel} to 100 for ${maxLabel}`
	},
};
