import { JSX }	from "preact";

import * as fbModel from "@geotoura/shared/fbModel";

import { useMessages }	from "@geotoura/fb/useMessages";

const sv	= fbModel.SliderValues;

export type SliderProps	= Readonly<{
	slider:	fbModel.SliderItem,
	action:	(value:number, keySlider:string) => void,
	value:	number,
}>;

export const Slider	= ({ slider, action, value }:SliderProps):JSX.Element => {
	const msg	= useMessages();

	const setSlider = (ev:JSX.TargetedInputEvent<HTMLInputElement>):void => {
		action(parseInt(ev.currentTarget.value), slider.keySlider);
	};

	const setSliderMin = ():void => action(sv.minimum, slider.keySlider);
	const setSliderMax = ():void => action(sv.maximum, slider.keySlider);

	// normalize the slider value in [min,max] to [0..1]
	const unitValue	= (value - sv.minimum) / (sv.maximum - sv.minimum);

	// NOTE we use aria-hidden because the same text is already in the aria-label for the input
	return (
		<div class="Slider">
			<div class="Slider-iconwrapper">
				<div class="Slider-iconlabelwrapper" onClick={setSliderMin}>
					<img src={slider.iconMin} alt="" class="Slider-icon Slider-icon-select" style={{ opacity: Math.max(1-unitValue, 0.1) }}/>
					<div class="Slider-label" aria-hidden="true">{slider.labelMin}</div>
				</div>
				<div class="Slider-iconlabelwrapper" onClick={setSliderMax}>
					<img src={slider.iconMax} alt="" class="Slider-icon" style={{ opacity: Math.max(0+unitValue, 0.1) }}/>
					<div class="Slider-label" aria-hidden="true">{slider.labelMax}</div>
				</div>
			</div>
			<div class="Slider-sliderwrapper">
				<input
					type="range"
					class="Slider-slider"
					min={sv.minimum}
					max={sv.maximum}
					value={value}
					step={1}
					onInput={setSlider}
					aria-label={msg.sliderLabel(slider.labelMin, slider.labelMax)}
				/>
				<div class="Slider-centerline"></div>
			</div>
		</div>
	);
};
