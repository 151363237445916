/* eslint-disable @stylistic/comma-dangle */

import { sharedLocale }	from "@geotoura/shared/sharedLocale_it";
import { numberFormat }	from "@geotoura/shared/numberFormat_it";
import * as fbModel		from "@geotoura/shared/fbModel";

import { Messages }		from "@geotoura/fb/locale";

export const messages:Messages = {
	format:	{
		number:	numberFormat,
	},
	nav: {
		start:		"Iniziare la pianificazione del viaggio",
		back:		"Indietro",
		continue:	"Avanti",
		skip:		"Salta",
		send:		"Invia",
		finish:		"Tornare alla pagina iniziale",
	},
	closeButton: {
		sr:	"Chiudere"
	},
	text: {
		errorHeading:	"Errore",
		errorText1:		"Purtroppo si è verificato un errore durante il salvataggio.",
		errorText2:		"Preghiamo di riprovare più tardi o contattarci al numero 0942 264463",
		mailLink:		sharedLocale.email.info,
		errorText3:		" o ",
		phoneLink:		sharedLocale.phone,
	},
	modal: {
		close:	"Chiudere",
		submit:	"OK"
	},
	selectlist: {
		selectRegion:	"Selezionare la regione"
	},
	sliderLabel: (minLabel:string, maxLabel:string):string =>
		`Seleziona un valore su una scala da ${fbModel.SliderValues.minimum} (${minLabel}) a ${fbModel.SliderValues.maximum} (${maxLabel})`,
	budget: {
		screenreaderHint: "È possibile scegliere uno dei quattro importi utilizzando i quattro pulsanti seguenti oppure regolarli in modo flessibile utilizzando un cursore.",
		sliderLabel: (minLabel:string, maxLabel:string):string =>
			`Scegli il tuo budget di viaggio totale su una scala da 0 per ${minLabel} a 100 per ${maxLabel}`
	},
};
