import { Nullable }		from "ts-base/nullable";

import { Refresh }		from "@v3/preact/refresh";

import { Logger }		from "@geotoura/shared/logger";
import * as i18n		from "@geotoura/shared/i18n";
import * as fbModel		from "@geotoura/shared/fbModel";

import * as pageInfo	from "@geotoura/common/pageInfo";

import { App }			from "@geotoura/fb/App";
import { Model }		from "@geotoura/fb/model";
import { Messages }		from "@geotoura/fb/locale";
import * as actions		from "@geotoura/fb/actions";

const logger	= Logger.create("fb-index");

const init	= ():void	=> {
	const languageCode:i18n.LanguageCode	=
		i18n.LanguageCode.valueOrDefault(document.documentElement.getAttribute("lang"));

	const msg	= Messages.of[languageCode];

	const container			= Nullable.unsafeGet(document.querySelector<HTMLElement>("#questionnaire"));

	const questionnaireId	= Nullable.then(container.getAttribute("data-id"))(fbModel.QuestionnaireId.fromString);
	const questionnaireName	= container.getAttribute("data-name");

	// if(/Android/.test(navigator.appVersion)) {
	window.addEventListener("resize", () => {
		if (document.activeElement !== null && (document.activeElement.tagName === "INPUT" || document.activeElement.tagName === "TEXTAREA")) {
			window.setTimeout(() => {
				document.activeElement?.scrollIntoView();
			}, 0);
		}
	});
	// }

	if (questionnaireId === null || questionnaireName === null) {
		logger.error("Could not run questionnaire, missing a questionnaire id");
		return;
	}

	const initialModel = Model.initial(questionnaireId, questionnaireName, pageInfo.get());

	const refresh:Refresh<Model> =
		Refresh.install({
			container:	container,
			initial:	initialModel,
			component:	model => <App languageCode={languageCode} messages={msg} model={model}/>,
		});
	actions.setRefresh(refresh);

	actions.boot(questionnaireId, languageCode);

	// open the survey from php-generated buttons
	document.querySelectorAll<HTMLButtonElement>(".js-open-survey").forEach((button) =>
		button.addEventListener("click", () =>
			actions.setFullscreen()
		)
	);
};

window.addEventListener("DOMContentLoaded", init);
