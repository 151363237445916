import { JSX }		from "preact";
import { useId }	from "preact/hooks";

import { Fn }			from "ts-base/fn";
import { Zoomer }		from "ts-base/zoomer";

import { ExternalLink }	from "@v3/geko/ExternalLink";

import * as fbModel		from "@geotoura/shared/fbModel";

import { Model, FormValidity }	from "@geotoura/fb/model";
import * as actions				from "@geotoura/fb/actions";

export type ContactProps	= Readonly<{
	screen:			fbModel.ScreenOfContact,
	answer:			fbModel.AnswerOfContact,
	formValidity:	FormValidity,
}>;

export const Contact = ({ screen, answer, formValidity }:ContactProps):JSX.Element => {
	const commentUid	= useId();
	const mailUid		= useId();
	const privacyUid	= useId();

	const zoomContact	= actions.answerContent(screen.id).cast<fbModel.AContact>();

	const setMail = (ev:JSX.TargetedInputEvent<HTMLInputElement>):void => {
		actions.doRefresh(
			Fn.andThen(
				zoomContact.atKey("returnMail").set(ev.currentTarget.value),
				Zoomer.on<Model>().atKey("formDirty").set(true)
			)
		);
	};

	const setComment = (ev:JSX.TargetedInputEvent<HTMLTextAreaElement>):void => {
		actions.doRefresh(
			zoomContact.atKey("returnTextarea").set(ev.currentTarget.value)
		);
	};

	const toggleCheckbox = ():void => {
		actions.doRefresh(
			Fn.andThen(
				zoomContact.atKey("returnCheckbox").mod((it:boolean) => !it),
				Zoomer.on<Model>().atKey("formDirty").set(true)
			)
		);
	};

	return (
		<div class="Contact Screen">
			<div class="heading p-center">{screen.content.title}</div>
			<div class="fb-field fb-field--vertical">
				<label class="fb-paragraph" for={commentUid}>{screen.content.introTextarea}</label>
				<textarea
					onInput={setComment}
					id={commentUid}
					class="fb-textarea"
					rows={4}
				>{answer.content.returnTextarea}</textarea>
			</div>
			<div class="fb-field fb-field--vertical">
				<label class="fb-label" for={mailUid}>{screen.content.labelMail}</label>
				<input
					class="fb-input" type="text"
					onInput={setMail}
					id={mailUid}
					value={answer.content.returnMail}
				/>
			</div>
			{
				formValidity.dirty && !formValidity.email &&
				<div class="fb-validation-error">{screen.content.requiredMsgMail}</div>
			}
			<div class="fb-field input-checkbox-wrapper">
				<input
					onClick={toggleCheckbox}
					type="checkbox" class="fb-input-checkbox input-checkbox"
					id={privacyUid}
					checked={ answer.content.returnCheckbox }
				/>
				<label class="input-checkbox-label" for={privacyUid}>
					{screen.content.labelCheckbox1}
					{" "}
					<ExternalLink
						label={screen.content.labelLinkTitle}
						layout="always-label"
						style="link"
						href={screen.content.labelLink}
					/>
					{" "}
					{screen.content.labelCheckbox2}
				</label>
			</div>
			{
				formValidity.dirty && !formValidity.checked &&
				<div class="fb-validation-error">{screen.content.requiredMsgCheckbox}</div>
			}
		</div>
	);
};
