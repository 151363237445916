import { JSX }	from "preact";

import { ExternalLink }	from "@v3/geko/ExternalLink";

import * as fbModel		from "@geotoura/shared/fbModel";

import { useMessages }	from "@geotoura/fb/useMessages";

export type TextProps = Readonly<{
	screen:			fbModel.ScreenOfText,
	serverResponse:	string,
}>;

export const Text = ({ screen, serverResponse }:TextProps):JSX.Element => {
	const msg = useMessages();

	return (
		<div class="Text Screen">
			{
				serverResponse === "error" &&
				<div>
					<div class="heading">{msg.text.errorHeading}</div>
					<div class="fb-paragraph">{msg.text.errorText1}</div>
					<div class="fb-paragraph">
						{msg.text.errorText2}
						{" "}
						<ExternalLink
							label={msg.text.mailLink.text}
							layout="always-label"
							style="link"
							href={msg.text.mailLink.href}
						/>
						{" "}
						{msg.text.errorText3}
						{" "}
						<ExternalLink
							label={msg.text.phoneLink.text}
							layout="always-label"
							style="link"
							href={msg.text.phoneLink.href}
						/>.
					</div>
					<div class="Text-greeting2 fancy">{screen.content.greeting2}</div>
				</div>
			}
			{	serverResponse === "ok" &&
				/* DO NOT CHANGE: "conversion-questionnaire" is used for conversion tracking by Google Tag Manager */
				<div class="conversion-questionnaire">
					<div class="heading">{screen.content.title}</div>
					<div class="Text-icon-wrapper">
						<img class="Text-icon" src={screen.content.icon} alt=""/>
					</div>
					{
						screen.content.paragraphs.map(it =>
							<div class="fb-paragraph">{it}</div>
						)
					}
					<div class="Text-greeting1">{screen.content.greeting1}</div>
					<div class="Text-greeting2 fancy">{screen.content.greeting2}</div>
				</div>
			}
		</div>
	);
};
